import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const createStore = () => {
  return new Vuex.Store({
    state: {
      fields: []
    },
    mutations: {
      initFormManagerData (state, data) {
        state.formManagerData = data
        state.fields = state.formManagerData.initialcurrentfields
      },
      addFormManagerField (state, defaultFieldData) {
        state.fields.push(defaultFieldData)
      },
      removeFormManagerField (state, fieldNum) {
        state.fields.splice(fieldNum, 1)
      }
    },
    getters: {
      formManagerFields (state) {
        return state.fields
      },
      createFieldFormData (state) {
        // Generic getter to return all data needed for create field form
        return {
          namePrefix: state.formManagerData.nameprefix,
          states: state.formManagerData.states,
          transactionTypes: state.formManagerData.transactiontypes,
          validationTypes: state.formManagerData.validationtypes,
          fieldTypes: state.formManagerData.fieldtypes,
          reswarePartners: state.formManagerData.resware_partners,
          form_id: state.formManagerData.formid,
          title: '',
          is_required: 0,
          fields: [],
          options: [],
          show_on_transaction_type_id: [],
          show_on_state_id: [],
          is_conditional_on_partner: null,
          title_hide: 0,
          instructions_hide: 0,
          section_order:0
        }
      },
    }
  })
}

const store = createStore()

export default store
