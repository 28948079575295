<template>
    <div class="row m-b">
        <div class="col-12 mb-3">
            <label>Add Another" Button Text</label>
            <input class="form-control"
                   :name="`${nameprefix}[button_text]`"
                   type="text"
                   v-model="formData.button_text"
                   maxlength="125">
        </div>

        <div class="col-12" v-for="(field, i) in formData.fields">
            <div class="card border-0" :key="$id(`repeat-field-${i}`)">
                <div class="formField optionField card-header mb-0 text-light bg-dark border-0" :key="$id(`repeat-field-${i}`)">
                    <div class="formField_title" v-b-toggle="$id(`repeat-field-${i}`)">
                        <i class="ion-ios-albums m-r icon"></i>
                        <span v-text="`In Repeatable Group: Field  #${i + 1}: ${field.title ? field.title : '' }`" class="ml-2"></span>
                    </div>
                    <div class="formField_buttons">
                        <button @click="removeField(i)" class="formField_delete_btn" type="button">
                            <i class="ion-ios-trash icon m-r-md"></i>
                        </button>
                        <button class="formField_collapse_btn" type="button" v-b-toggle="$id(`repeat-field-${i}`)">
                            <unicon class="when-closed" fill="white" name="angle-down"></unicon>
                            <unicon class="when-opened" fill="white" name="angle-up"></unicon>
                        </button>
                    </div>
                </div>
                <b-collapse :id="$id(`repeat-field-${i}`)" class="card-body inner-card-body ">
                    <create-field-form :fieldData="field" :nameprefix="`${nameprefix}[fields][${i}]`" :order="i+1"></create-field-form>
                </b-collapse>
            </div>
        </div>


        <div class="col-12">
            <button type="button" class="btn btn-secondary" @click="addField()">
                <i class="ion-plus m-r-xs"></i>
                New Field In Repeatable Group
            </button>
        </div>
    </div>
</template>


<script>
  export default {
    name: 'repeat-field-group',
    props: ['formData', 'nameprefix'],
    components: {},
    data () {
      return {}
    },
    computed: {},
    created () {},
    methods: {
      removeField (index) {
        if (confirm('Are you sure you want to delete this field?')) {
          this.formData.fields.splice(index, 1)
        }
      },
      addField () {
        if (!this.formData.fields) {
          this.formData.fields = [JSON.parse(JSON.stringify(this.$store.getters.createFieldFormData))]
        } else {
          this.formData.fields.push(JSON.parse(JSON.stringify(this.$store.getters.createFieldFormData)))
        }

      },
    }
  }
</script>