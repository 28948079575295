<template>
  <div class="row m-b">
    <div class="col-12" v-for="(option, i) in formData.options" :key="$id(`field-checkbox-option-${i}`)">
      <div class="card border-0">
        <div class="formField optionField card-header mb-0 text-light bg-dark border-0"
             :key="$id(`field-checkbox-option-${i}`)">
          <div class="formField_title" v-b-toggle="$id(`field-checkbox-option-${i}`)">
              <i class="ion-ios-albums m-r icon"></i>
              <span v-text="`Checkbox #${i+1}: ${option.title ? option.title : '' }`" class="ml-2"></span>
          </div>
          <div class="formField_buttons">
            <button @click="removeOption(i)" class="formField_delete_btn" type="button">
                <i class="ion-ios-trash icon m-r-md"></i>
            </button>
            <button class="formField_collapse_btn" type="button"
                v-b-toggle="$id(`field-checkbox-option-${i}`)">
                <unicon class="when-closed" fill="white" name="angle-down"></unicon>
                <unicon class="when-opened" fill="white" name="angle-up"></unicon>
            </button>
          </div>
        </div>
        <b-collapse :id="$id(`field-checkbox-option-${i}`)" class="card-body inner-card-body ">
              <label>Checkbox #{{i+1}}:</label>
              <input type="text"
                     :name="`${nameprefix}[options][${i}][title]`"
                     placeholder="Checkbox label"
                     class="form-control"
                     v-model="option.title"/>
          </b-collapse>
      </div>
    </div>
    
    <div class="col-12">
      <button type="button" class="btn btn-secondary" @click="addOption">
          <i class="ion-plus m-r-xs"></i>
          Add Checkbox Option
      </button>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'checkbox-field-group',
    props: ['formData', 'nameprefix'],
    data () {
      return {}
    },
    created () {},
    methods: {
      removeOption (index) {
        if (confirm('Are you sure you want to delete this option?')) {
          this.$forceUpdate()
          this.formData.options.splice(index, 1)
        }
      },
      addOption () {
        this.$forceUpdate()
        if (!this.formData.options) {
          this.formData.options = [{ title: '' }]
        } else {
          this.formData.options.push({ title: '' })
        }
      }
    }
  }
</script>
