<template>
    <div class="row m-b">
        <div class="col-12" v-for="(option, i) in formData.options" :key="$id(`field-radio-option-${i}`)">
            <div class="card border-0">
                <div class="formField optionField card-header mb-0 text-light bg-dark border-0"
                     :key="$id(`field-radio-option-${i}`)">
                    <div class="formField_title" v-b-toggle="$id(`field-radio-option-${i}`)">
                        <i class="ion-ios-albums m-r icon"></i>
                        <span v-text="`Option #${i+1}: ${option.title ? option.title : '' }`" class="ml-2"></span>
                    </div>
                    <div class="formField_buttons">
                        <button @click="removeOption(i)" class="formField_delete_btn" type="button">
                            <i class="ion-ios-trash icon m-r-md"></i>
                        </button>
                        <button class="formField_collapse_btn" type="button"
                                v-b-toggle="$id(`field-radio-option-${i}`)">
                            <unicon class="when-closed" fill="white" name="angle-down"></unicon>
                            <unicon class="when-opened" fill="white" name="angle-up"></unicon>
                        </button>
                    </div>
                </div>
                <b-collapse :id="$id(`field-radio-option-${i}`)" class="card-body inner-card-body ">
                    <div class="mb-3">
                        <label>Option #{{i+1}}:</label>
                        <input type="text"
                               :name="`${nameprefix}[options][${i}][title]`"
                               placeholder="Radio label"
                               class="form-control"
                               v-model="option.title"/>
                    </div>
                    <form-field v-for="(field, index) in option.fields"
                                :key="$id(`field-option-${i}-${index}`)"
                                :field="field"
                                :fields="option.fields"
                                :fieldNum="index"
                                :nameprefix="`${nameprefix}[options][${i}][fields][${index}]`"
                                :order="index+1"
                    ></form-field>
                    <button type="button" class="btn btn-primary" @click="addField(i)">
                        <i class="ion-plus m-r-xs"></i>
                        New Conditionally Displayed Field
                    </button>
                </b-collapse>
            </div>
        </div>

        <div class="col-12">
            <button type="button" class="btn btn-secondary" @click="addOption">
                <i class="ion-plus m-r-xs"></i>
                Add Radio Option
            </button>
        </div>
    </div>
</template>


<script>

  export default {
    name: 'radio-field-group',
    props: ['formData', 'nameprefix'],
    data () {
      return {}
    },
    computed: {},
    created () {},
    methods: {
      removeOption (index) {
        if (confirm('Are you sure you want to delete this option?')) {
          this.formData.options.splice(index, 1)
        }
      },
      addOption () {
        const option = {
          title: '',
          fields: []
        }
        if (!this.formData.options) {
          this.formData.options = [option]
        } else {
          this.formData.options.push(option)
        }
      },
      addField (index) {
        console.log(this.formData.options[index].fields)
        if (!this.formData.options[index].fields) {
          this.formData.options[index].fields = [JSON.parse(JSON.stringify(this.$store.getters.createFieldFormData))]
        } else {
          this.formData.options[index].fields.push(JSON.parse(JSON.stringify(this.$store.getters.createFieldFormData)))
        }
      }
    }
  }
</script>