/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import UniqueId from 'vue-unique-id';
import { uniAngleDown, uniAngleUp, uniCarWash, uniConstructor, uniMultiply, uniPlus } from 'vue-unicons/dist/icons';
import Fragment from 'vue-fragment';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store/index'

Unicon.add([
  uniConstructor,
  uniCarWash,
  uniAngleUp,
  uniAngleDown,
  uniMultiply,
  uniPlus
])

require('./bootstrap');

window.Vue = require('vue').default;
window.Vue.use(Fragment.Plugin)
window.Vue.use(Vuex)
window.Vue.use(BootstrapVue)
window.Vue.use(Unicon)
window.Vue.use(UniqueId)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('form-manager-app', require('./FormManagerApp.vue').default);
Vue.component('form-field', require('./components/fields/FormField.vue').default);
Vue.component('create-field-form', require('./components/CreateFieldForm.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store
}).$mount('#app');
