<template>
    <span>
        <div class="formField" :key="fieldNum">
            <div class="formField_title" v-b-toggle="$id(`field-body-${fieldNum}`)">
                <i class="ion-ios-albums m-r icon "></i>
                <!-- <span v-text="`Field ${fieldNum + 1}: ${field.title ? field.title : '' }`"></span> -->
                <span v-text="`${field.title ? field.title : '' }`"></span>
            </div>

            <div class="formField_buttons">
                <button @click="removeField(fieldNum)" class="formField_delete_btn" type="button">
                    <i class="ion-ios-trash icon m-r-md text-default"></i>
                </button>

                <button class="formField_collapse_btn" type="button" v-b-toggle="$id(`field-body-${fieldNum}`)">
                    <unicon class="when-closed" fill="white" name="angle-down"></unicon>
                    <unicon class="when-opened" fill="white" name="angle-up"></unicon>
                </button>
            </div>
        </div>
        
        <b-collapse :id="$id(`field-body-${fieldNum}`)" class="mt-2">
            <create-field-form :fieldData="field" :nameprefix="nameprefix" :order="order"></create-field-form>
        </b-collapse>
    </span>
</template>

<script>
  export default {
    name: 'form-field',
    props: ['field', 'fields', 'fieldNum', 'nameprefix','order'],
    components: {},
    data () {
      return {
        fields_list: this.fields
      }
    },
    created () {},
    computed: {},
    methods: {
      removeField (index) {
        var delete_field = this.fields.splice(index, 1);
        if (confirm('Are you sure you want to delete this field?')) {
            this.fields.splice(index, 1)
            axios.post('/admin/formmanager/deleteFields', {
                id: delete_field[0].id,
                form_id: delete_field[0].form_id
            }).then((response) => {
                console.log('line no response');
            });
        }
      }
    }
  }
</script>
