<template>
  <div class="formManager">
      <div class="formManagerActions">
          <b-button @click="addNewField" class="addFormField_btn" variant="primary">
              <i class="ion-plus m-r-xs"></i>New Form Field
          </b-button>
      </div>

      <div class="formManager__fields" :key="currentFields.length">
        <draggable :list="currentFields" :options="{}" :element="'span'" @change="updateFields()">
          <form-field v-for="(item, i) in currentFields"
                      :key="$id(`level0-${i}`)"
                      :fields="currentFields"
                      :field="item"
                      :fieldNum="i"
                      :nameprefix="`${nameprefix}[${i}]`"
                      :order="i+1"
          ></form-field>
        </draggable>
      </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import FormField from './components/fields/FormField'
  export default {
    name: 'form-manager-app',
    props: [
      'formid',
      'fieldtypes',
      'validationtypes',
      'initialcurrentfields',
      'nameprefix',
      'addfieldbuttontext',
      'fieldlabelprefix',
      'transactiontypes',
      'states',
      'resware_partners'
    ],
    components: {
      FormField,
      draggable
    },
    data () {
      return {
        formFields: null,
      }
    },
    computed: {
      currentFields() {
        this.formFields = this.$store.getters.formManagerFields;
        return this.$store.getters.formManagerFields;
      },
    },
    created () {
      this.$store.commit('initFormManagerData', this.$props)
    },
    mounted(){
      this.updateFields();
    },
    methods: {
      addNewField () {
        this.$store.commit('addFormManagerField', JSON.parse(JSON.stringify(this.$store.getters.createFieldFormData)));
      },
      updateFields(){
        this.currentFields.map((field, index) => {
          field.section_order = index + 1
        });
        axios.post('/admin/formmanager/updateFields', {
          fields: this.formFields,
          form_id: this.formid
        }).then((response) => {
          console.log('line no response');
        });
      }
    }
  }
</script>