<template>
  <div class="row m-b">
    <div class="col-6">
        <label>Text / HTML:</label>
        <textarea class="form-control"
                  type="text"
                  cols="50"
                  rows="10"
                  :name="`${nameprefix}[text_html]`"
                  v-model="formData.text_html"
        ></textarea>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'textHtml-field',
    props: ['formData', 'nameprefix']
  }
</script>
