<template>
    <div class="formField_expandedForm">
        <input :name="`${nameprefix}[id]`" type="hidden" v-model="fieldData.id"/>
        <input :name="`${nameprefix}[form_id]`" type="hidden" v-model="fieldData.form_id"/>
        <div class="wrapper-lg">
            <div class="row m-b">
                <div class="col-3">
                    <label>
                        <strong>Field is Required:</strong>
                    </label>
                </div>
                <div class="col-4">
                    <input :name="`${nameprefix}[is_required]`" type="hidden" v-model="fieldData.is_required"/>
                    <input class="form-check-input"
                           false-value="0"
                           true-value="1"
                           type="checkbox"
                           v-model="fieldData.is_required"/>
                </div>
            </div>

            <div class="row m-b">
                <div class="col-3"> <input type="button" value="Show Conditional Settings" @click="showExpandableFields = !showExpandableFields" /> </div>
                <div class="col-4"> </div>
            </div>            

            <div class="row m-b" v-show="showExpandableFields">
                <div class="col-8">
                    <label>
                        <strong>Only display this field for these transaction types:</strong>
                    </label>

                    <br/>

                    <div style="float:left; width: 20%;"
                         v-for="(trName,trId) in defaultFieldFormData.transactionTypes"
                         :key="`${trName}`"
                    >
                        <input :name="`${nameprefix}[show_on_transaction_type_id][]`"
                               :value="trId"
                               type="checkbox"
                               :checked="isTransactionTypeChecked(trId)"
                        />
                        {{ trName }}
                    </div>
                    <div style="clear:left;"></div>
                </div>
            </div>
            <!-- end of conditional transaction row -->

            <div class="row m-b" v-show="showExpandableFields">
                <div class="col-12">
                    <label>
                        <strong>Only display this field for transactions in these states:</strong>
                    </label>
                    <br/>
                    <div :key="state_id"
                         style="float:left; width: 30%;"
                         v-for="(state_name, state_id) in defaultFieldFormData.states"
                    >
                        <input :name="`${nameprefix}[show_on_state_id][]`"
                               :value="state_id"
                               type="checkbox"
                               :checked="isStateChecked(state_id)"
                        />
                        {{state_name}}
                    </div>
                    <div style="clear:left;"></div>
                </div>
            </div>
            <!-- end of conditional state row -->

            <div class="row m-b" v-show="showExpandableFields">
                <div class="col-7">
                    <strong>
                        Only display this field when customer
                        <select :name="`${nameprefix}[is_conditional_on_partner]`"
                                v-model="fieldData.is_conditional_on_partner"
                                style="display:inline;">
                            <option value="">[no rule]</option>
                            <option value="1">Has</option>
                            <option value="0">Does Not Have</option>
                        </select>
                        any of the following partners associated:
                    </strong>
                    <br/>
                    <div :key="resware_partner_id"
                         style="float:left; width: 50%;"
                         v-if="fieldData.is_conditional_on_partner !== ''"
                         v-for="(partner_name,resware_partner_id) in defaultFieldFormData.reswarePartners"
                    >
                        <input :name="`${nameprefix}[resware_partner_id][]`"
                               :value="resware_partner_id"
                               :checked="isReswarePartnerChecked(resware_partner_id)"
                               type="checkbox"/>
                        {{partner_name}}
                    </div>
                    <div style="clear:left;"></div>
                </div>
            </div>

            <div class="row m-b">
                <div class="col-6">
                    <label>Field Title</label>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input :name="`${nameprefix}[title_hide]`" type="hidden" v-model="fieldData.title_hide"/>
                            <input class="form-check-input"
                                   false-value="0"
                                   true-value="1"
                                   type="checkbox"
                                   v-model="fieldData.title_hide"/>
                            Hide
                        </label>
                    </div>
                    <input :name="`${nameprefix}[title]`" class="form-control" maxlength="250" type="text"
                           v-model="fieldData.title"/>
                </div>
            </div>

            <div class="row m-b">
                <div class="col-6">
                    <label>Section Order</label>
                    <input :name="`${nameprefix}[section_order]`" class="form-control" maxlength="250" type="text"
                           v-model="fieldData.section_order"/>
                </div>
            </div>

            <div class="row m-b">
                <div class="col-6">
                    <label>Field Type</label>
                    <select :name="`${nameprefix}[fieldtype_id]`"
                            class="form-control required"
                            id="formField.field_type_id"
                            v-model="fieldData.fieldtype_id"
                            @change="loadFieldComponent"
                    >
                        <option :value="null">* Select Field Type</option>
                        <option :key="fieldtype_id"
                                :value="fieldtype_id"
                                v-for="(type_name, fieldtype_id) in defaultFieldFormData.fieldTypes"
                        >{{defaultFieldFormData.fieldTypes[fieldtype_id]}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row m-b">
                <div class="col-12">
                    <label>Field Instruction Text</label>
                    <div class="form-check">
                        <label class="form-check-label">
                            <input :name="`${nameprefix}[instructions_hide]`" type="hidden"
                                   v-model="fieldData.instructions_hide"/>
                            <input type="checkbox"
                                   class="form-check-input"
                                   false-value="0"
                                   true-value="1"
                                   v-model="fieldData.instructions_hide"
                            /> Hide
                        </label>
                    </div>
                    <textarea :name="`${nameprefix}[instructions]`"
                              id="formField.instruction_text"
                              class="form-control"
                              cols="50"
                              rows="10"
                              v-model="fieldData.instructions"
                    ></textarea>
                </div>
            </div>

            <component :is="fieldType" :formData="fieldData" :nameprefix="`${nameprefix}`"
                       :key="this.fieldData.fieldtype_id" :order="order"></component>
        </div>
    </div>
</template>

<script>
  import TextHtmlField from './fields/field-types/TextHtmlField'
  import CheckboxFieldGroup from './fields/field-types/CheckboxFieldGroup'
  import RadioFieldGroup from './fields/field-types/RadioFieldGroup'
  import RepeatFieldGroup from './fields/field-types/RepeatFieldGroup'
  export default {
    name: 'create-field-form',
    props: ['fieldData', 'index', 'nameprefix','order'],
    components: {
      TextHtmlField,
      CheckboxFieldGroup,
      RadioFieldGroup,
      RepeatFieldGroup
    },
    data () {
      return {
        fieldType: null,
        showExpandableFields:false,
      }
    },
    mounted(){
      this.fieldData.section_order = this.order;
    },
    computed: {
      defaultFieldFormData () {
        return this.$store.getters.createFieldFormData
      }
    },
    created () {
      this.loadFieldComponent()
      this.isConditionalOnPartner()
    },
    methods: {
      loadFieldComponent () {
        this.$forceUpdate()
        const fieldTypeId = parseInt(this.fieldData.fieldtype_id)
        switch (fieldTypeId) {
          case 2:
            this.fieldType = CheckboxFieldGroup
            break
          case 3:
            this.fieldType = RadioFieldGroup
            break
          case 7:
            this.fieldType = RepeatFieldGroup
            break
          case 10:
            this.fieldType = TextHtmlField
            break
          default:
            this.fieldType = null
        }
      },
      isTransactionTypeChecked (id) {
        id = parseInt(id)
        if (this.fieldData.show_on_conditional_transaction_type_ids) {
          return this.fieldData.show_on_conditional_transaction_type_ids.filter(({ conditional_rule_value_id }) => conditional_rule_value_id === id).length > 0
        }
      },
      isStateChecked (id) {
        id = parseInt(id)
        if (this.fieldData.show_on_conditional_state_ids) {
          return this.fieldData.show_on_conditional_state_ids.filter(({ conditional_rule_value_id }) => conditional_rule_value_id === id).length > 0
        }
      },
      isReswarePartnerChecked (index) {
        index = parseInt(index)
        if (this.fieldData.conditional_partners) {
          return this.fieldData.conditional_partners.filter(({ id }) => id === index).length > 0
        }
      },
      isConditionalOnPartner () {
        if (this.fieldData.is_conditional_on_partner === null) {
          this.fieldData.is_conditional_on_partner = ''
        }
      }
    }
  }
</script>